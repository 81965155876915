.inputGroup {
	width: 100%;
	display: flex;
	flex-direction: column;
	row-gap: 0.5rem;
	& > div {
		padding-left: 1rem;
	}
}
.helper {
	padding: 1rem;
	& > a {
		margin: 0 1.5rem;
		color: var(--button);
	}
}
