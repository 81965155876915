.rootLoginBox {
	color: white;
	background-color: black;
	min-width: 300px;
	max-height: 700px;
	padding: 1rem;
	.loginBox {
		padding: 3rem;
		padding-bottom: 2rem;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.prevButton {
		position: relative;
		width: 100%;
		opacity: 1;
		&:hover {
			opacity: 0.7;
		}
		svg {
			cursor: pointer;
			position: absolute;
			top: 0.4rem;
			left: 0;
			font-size: 2rem;
		}
	}
}

.loginContainer {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: url("../assets/login-background.png");
	background-size: cover;
	background-position: center center;
	nav {
		position: absolute;
		right: 0;
		top: 0;
		padding: 0.5rem;
		a,
		svg {
			color: var(--button);
			text-decoration: unset;
		}
		a {
			display: flex;
		}
	}
}
