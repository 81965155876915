.content-wrap {
	background-color: var(--white);
	border: 1px solid var(--disabled);
	border-radius: 10px;
	padding: 30px;

	.title {
		font-size: 20px;
		font-weight: 800;
		margin-bottom: 30px;
	}

	.feature-container {
		display: flex;
		max-width: 1000px;

		div {
			flex: 3;
			justify-content: center;
		}

		.type {
			flex: 1;
		}

		.form-container {
			border: 1px solid var(--disabled);
			border-radius: 10px;
			padding: 20px;
		}
	}

	.li-containers-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		max-width: 1000px;

		.li-container:last-child {
			margin-bottom: 30px;
		}
	}

	.li-container {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 30px;

		.li-label {
			width: 100px;
		}

		.li-label-long {
			width: 180px;
		}

		.li-label-fit-content {
			width: fit-content;
			margin-right: 10px;
		}

		.li-value {
			display: flex;
			flex-direction: column;
		}

		.li-disabled {
			color: var(--disabled);
		}

		.li-enabled {
			color: var(--status);
		}
	}

	.li-container:last-child {
		margin-bottom: 0;
	}

	.li-button {
		margin-left: 100px;
		margin-bottom: 30px;
	}

	.li-button-long {
		margin-left: 180px;
	}

	.li-button-auth {
		margin-left: 0;
	}

	.li-button-role {
		margin-left: 0;
		margin-bottom: 0;
	}

	button:first-child {
		margin-right: 5px;
	}

	.side-button {
		margin-left: 20px;
		margin-bottom: 0;
	}
}
