:root {
	--main: #0d3d6e;
	--second: #4edeea;
	--warning: #ff0000;
	--disabled: #707070;
	--button: #009fdd;
	--log-inout: #00baff;
	--status: #54c3b0;
	--black: black;
	--white: white;
}
:global {
	.clickable {
		cursor: pointer;
		opacity: 1;
		&:hover {
			opacity: 0.7;
		}
	}
}
