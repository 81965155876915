.heading {
	text-transform: capitalize;
}
.appInfo {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 2rem;
	p {
		max-width: 4rem;
		img {
			width: 100%;
		}
	}
	div {
		text-transform: capitalize;
	}
}
.inputGroup {
	width: 100%;
	display: flex;
	flex-direction: column;
	row-gap: 0.5rem;
	& > div {
		padding-left: 1rem;
	}
	input::placeholder {
		text-transform: capitalize;
	}
	input[name="ga"] {
		-moz-appearance: textfield;
		&::placeholder {
			text-transform: uppercase;
		}
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}
}
.helper {
	padding: 1rem;
	& > a {
		margin: 0 1.5rem;
		color: var(--button);
	}
}
