.codeInput {
	width: 50%;
	& input {
		background-color: black;
		color: white;
		font-size: 3rem;
		text-align: center;
		width: 100%;
	}
}
